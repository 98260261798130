import { Form, Input } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { isnotnull } from '../../libs/util';

const Para = forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(false);
    const [paraDoctor, setParaDoctor] = useState();
    const [paraDiagnostic, setParaDiagnostic] = useState();
    const [para, setPara] = useState();

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        },
        getState: () => {
            return getState();
        },
        reset: () => {
            reset();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setDisabled(props.disabled)
    }, [props.disabled]);
    
    useEffect(() => {
        const data = props.data;
        if (data) {
            if (isnotnull(data.paraDoctor)) setParaDoctor(data.paraDoctor);
            if (isnotnull(data.paraDiagnostic)) setParaDiagnostic(data.paraDiagnostic);
            if (isnotnull(data.para)) setPara(data.para);
        }
    }, [props.data]);

    const getState = () => {
        let data = {
            para,
            paraDoctor,
            paraDiagnostic
        }
        return data;
    }

    const reset = () => {
        setPara();
        setParaDoctor();
        setParaDiagnostic();
    }

    return (
        <div className="d-flex flex-wrap justify-content-between">
            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                <label>Bác sĩ lấy mẫu</label>
                <Form.Item>
                    <Input disabled={disabled} placeholder='Bác sĩ lấy mẫu' value={paraDoctor} onChange={(e) => setParaDoctor(e.target.value)} />
                </Form.Item>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                <label>Chẩn đoán</label>
                <Form.Item>
                    <Input disabled={disabled} placeholder='Chẩn đoán' value={paraDiagnostic} onChange={(e) => setParaDiagnostic(e.target.value)} />
                </Form.Item>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                <label>PARA</label>
                <Form.Item>
                    <Input disabled={disabled} placeholder='PARA' value={para} onChange={(e) => setPara(e.target.value)} />
                </Form.Item>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Para)